@import '../../../../styles/variables';
@import '../../../../styles/colors.module.scss';

.scrollToTopBtn {
  position: fixed;
  bottom: 10px;
  right: 25px;
  padding: 0px;
  width: 64px;
  height: 64px;
  border-radius: 9.6px;
  background-color: $colorWhite;
  border: 1px solid $colorDarkBlue;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: $minWidthMd) {
  .scrollToTopBtn {
    width: 64px;
    height: 64px;
  }
}

@media screen and (max-width: $maxWidthSm) {
  .scrollToTopBtn {
    width: 48px;
    height: 48px;
  }
}
