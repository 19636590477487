@import '../../styles/colors.module.scss';
@import '../../styles/variables';

.footerWrapperContainer,
.logosBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footerRightColumn {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.logosBlockRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.footerWrapperContainer {
  background: $colorWhite;
  padding-top: 40px;
  width: 90%;
  margin: auto;
  justify-content: center;
}

.footerContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 48px;
  // border-bottom: 1px solid $colorGrey30;
}

.logosBlock {
  justify-content: flex-start;
}
.ageRestrictionLogo {
  color: $colorGrey50;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-top: 12px;
}

.iconsBlock {
  display: flex;
  column-gap: 34px;
  row-gap: 20px;
}

.legalInfoBlockText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $colorGrey100;
  text-decoration: none;
  max-width: 680px;
  margin-left: 42px;

  div {
    margin-bottom: 10px;
  }
}

.agreementsBlock {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 0px;
}

.agreementsBlockLink {
  color: $colorBlack;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.agreementsBlockDevider {
  border-left: 1px solid $colorBlack;
  height: 16px;
  margin: 6px 12px 6px 12px;
}

@media screen and (max-width: $maxWidthSm) {
  .agreementsBlock,
  .logosBlockRow {
    flex-direction: column;
  }

  .footerWrapperContainer {
    padding-bottom: 38px;
  }

  .footerContainer {
    padding-bottom: 10px;
  }

  .footerRightColumn {
    flex-direction: column-reverse;
  }

  .legalInfoBlockText {
    font-size: 14px;
    line-height: 125%;
    margin-left: 0px;
  }

  .legalInfoBlockText,
  .iconsBlock {
    margin-top: 30px;
  }

  .agreementsBlock {
    margin: 12px 0px;
  }

  .agreementsBlockDevider {
    display: none;
  }
}
