@import '../../styles/variables';

.wrapper {
  width: 100%;
  height: 70px;
  background: #fcfcfc;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 1002;

  @media screen and (max-width: $minWidthSm) {
    height: 68px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 90%;
}

.logoWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 61px;
  margin-top: 11px;

  @media screen and (max-width: $minWidthSm) {
    width: 151px;
    height: 40px;
  }
}

.headerMenu {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: $minWidthSm) {
    display: none;
  }
}

.headerMenuItems {
  display: flex;
  min-width: 840px;
  width: 100%;
  overflow: hidden;
  justify-content: space-around;

  @media (max-width: $screen-lg) {
    justify-content: space-between;
    padding: 0 20px;
  }
}

.scrollContainer {
  overflow: hidden;
}

.scrollButton {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 69px;
  width: 90px;
}

.scrollRight {
  position: absolute;
  left: auto;
  right: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), #fff);
  cursor: pointer;
  justify-content: flex-end;
}

.scrollLeft {
  position: absolute;
  left: 0;
  right: auto;
  background-image: linear-gradient(to left, rgba(255, 255, 255, -0.1), #fff);
  cursor: pointer;
  justify-content: flex-start;

  div {
    transform: rotate(180deg);
  }
}

.link {
  padding: 1px 0;
  margin: 26px 0;
  text-transform: uppercase;
  color: #000;
  font-size: 9px;
  font-weight: 700;
  border-bottom: 2px solid transparent;
  letter-spacing: 0.3px;

  &:hover {
    color: #1964e7;
    border-bottom-color: #1964e7;
  }
}

.active {
  color: #1964e7;
  border-bottom-color: #1964e7;
}

.userControl {
  display: flex;
  min-width: 180px;

  :not(:last-child) {
    border-right: 1px solid #ddd;
  }

  @media screen and (max-width: $minWidthSm) {
    display: none;
  }
}

.userControlButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 26px;
  cursor: pointer;
  position: relative;
}

.menuExpand {
  display: none;
  button {
    &:hover {
      cursor: pointer;
    }
    border: none;
    background: none;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: $minWidthSm) {
    display: block;
  }
}
