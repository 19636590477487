@import '@fontsource/inter';
@import './_variables';
@import './colors.module.scss';

body {
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  //     Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //     sans-serif;
  //   font-family: var(--font-global-sans);

  .sideBar {
    border: 1px solid rgb(255, 221, 0);
    margin: 5px 25px 10px 0px;
    padding: 0px 16px;
    width: 250px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    float: left;
    clear: left;
    user-select: none;
  }
}

.infinite-scroll-component {
  overflow-x: hidden;
}

@media screen and (min-width: $minWidthSm) {
  .advertisementDesktopBlock {
    // display: block;
    height: auto;
  }
  .advertisementMobileBlock {
    // display: none;
    height: 0px;
  }
}

@media screen and (max-width: $maxWidthXs) {
  .advertisementDesktopBlock {
    height: 0px;
    // display: none;
  }
  .advertisementMobileBlock {
    // display: block;
    height: auto;
  }
}

.mainForTheDaySwiperButtonDisabled svg path {
  fill: $colorGrey50;
}

.swiper {
  display: flex;
  flex-direction: column;
}

.swiper-pagination-bullets {
  display: flex;
  flex-direction: row;
}

.swiper-pagination-bullet-active {
  background-color: $colorMain !important;
}

.swiper-pagination {
  margin-left: -3px;
}

.mainForTheDaySwiperButtons {
  display: flex;
  flex-direction: row-reverse;
  z-index: 20;
  right: 0px;
  position: absolute;
  bottom: 0;
  // position: absolute;
  // bottom: -2px;
  // right: 0px;
}

.mainForTheDaySwiperButtonPrev {
  margin-right: 24px;
}
