@import '../../../../styles/_variables';
@import '../../../../styles/colors.module.scss';
@import '@fontsource/inter';

.shareBlockWrapper {
  display: flex;
  flex-direction: column;
}

.shareBlockText,
.shareBlockTextNight {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  vertical-align: middle;
  margin-bottom: 8px;
}

.shareBlock {
  display: flex;
  flex-direction: row;
}

.shareBlockTextNight {
  color: $colorWhite;
}

@media screen and (max-width: $maxWidthSm) {
  .shareBlockText,
  .shareBlockTextNight,
  .shareBlockPrinterBtn {
    display: none;
  }
}
